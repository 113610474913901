import "@/styles/globals.scss"
import AppPageTwo from "@/bet-nextv1/AppPageTwo";
import user from "@/core-nextv3/model/user";
import { THEME_SETTING } from "@/setting/setting";
import { useContext, useEffect } from "react";
import { useRouter } from "next/router";
import { AuthContext } from "@/bet-nextv1/context/AuthContext";

function MyApp({ Component, pageProps }: any) 
{
    return (
        <AppPageTwo Component={Component} pageProps={pageProps} />
    )
}

export default MyApp;
