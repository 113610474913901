import { TDate } from "../model/TDate";

const validateDate = (value: any, mask = 'dd/MM/yyyy') =>
{
	const date = new TDate({value: value, mask: mask});

	return date.isValid();
}

const validateCEP = (value: any) =>
{
	return (/^[0-9]{5}-[0-9]{3}$/).test(value);
}

const validateCNPJ = (cnpj: any) =>
{
	cnpj = cnpj.replace(/[^\d]+/g, "");
  
	if (cnpj == "") return false;
  
	if (cnpj.length != 14) return false;
  
	// Elimina CNPJs invalidos conhecidos
	if (
	  cnpj == "00000000000000" ||
	  cnpj == "11111111111111" ||
	  cnpj == "22222222222222" ||
	  cnpj == "33333333333333" ||
	  cnpj == "44444444444444" ||
	  cnpj == "55555555555555" ||
	  cnpj == "66666666666666" ||
	  cnpj == "77777777777777" ||
	  cnpj == "88888888888888" ||
	  cnpj == "99999999999999"
	)
	  return false;
  
	// Valida DVs
	let tamanho = cnpj.length - 2;
	let numeros = cnpj.substring(0, tamanho);
	let digitos = cnpj.substring(tamanho);
	let soma = 0;
	let pos = tamanho - 7;
	for (let i = tamanho; i >= 1; i--) {
	  soma += numeros.charAt(tamanho - i) * pos--;
	  if (pos < 2) pos = 9;
	}
	let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
	if (resultado != digitos.charAt(0)) return false;
  
	tamanho = tamanho + 1;
	numeros = cnpj.substring(0, tamanho);
	soma = 0;
	pos = tamanho - 7;
	for (let i = tamanho; i >= 1; i--) {
	  soma += numeros.charAt(tamanho - i) * pos--;
	  if (pos < 2) pos = 9;
	}
	resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
	if (resultado != digitos.charAt(1)) return false;
  
	return true;
}

const validatePhone = (value: any) =>
{  
	if (value == undefined || null) return false;
  
	value = value.replace("(", "").replace(")", "").replace("-", "").replace("_", "").replace(" ", "").trim();
	
	return value.length >= 11;
}

const validatePhoneV2 = (value: any) =>
{  
	if (value == undefined || null) return false;
  
	value = value.replace("(", "").replace(")", "").replace("-", "").replace("_", "").replace(" ", "").trim();
	
	return value.length >= 10;
}

const validateName = (value: any) =>
{  
		if(!value)
		{
				return false;
		}
	
		const re = /^([^0-9]*)$/g;
    return re.test(String(value).toLowerCase());
}

const validateFullName = (value: any) => 
{  
    if (!value || typeof value !== 'string') {
        return false;
    }
    
    // Expressão regular para nome completo: duas palavras ou mais, sem números ou caracteres especiais
    const re = /^[A-Za-zÀ-ÖØ-öø-ÿ]+(?:\s+[A-Za-zÀ-ÖØ-öø-ÿ]+)+$/;
    return re.test(value.trim());
}

const validateCpf = (cpf: any) =>
{
	var sum = 0;
	var remainder;
  
	if (!cpf) return false;
  
	cpf = cpf.replace(".", "").replace(".", "").replace("-", "").trim();
  
	var allEqual = true;
	for (var i = 0; i < cpf.length - 1; i++) {
	  if (cpf[i] != cpf[i + 1]) allEqual = false;
	}
	if (allEqual) return false;
  
	for (i = 1; i <= 9; i++)
	  sum = sum + parseInt(cpf.substring(i - 1, i)) * (11 - i);
	remainder = (sum * 10) % 11;
  
	if (remainder == 10 || remainder == 11) remainder = 0;
	if (remainder != parseInt(cpf.substring(9, 10))) return false;
  
	sum = 0;
	for (i = 1; i <= 10; i++)
	  sum = sum + parseInt(cpf.substring(i - 1, i)) * (12 - i);
	remainder = (sum * 10) % 11;
  
	if (remainder == 10 || remainder == 11) remainder = 0;
	if (remainder != parseInt(cpf.substring(10, 11))) return false;
  
	return true;
}

const validateEmail = (email: any) =>
{
	const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

const validateCpfCnpj = (value: any) => {

	if(value?.length == 14) {
		return validateCpf(value)
	} else if (value?.length > 15) {
		return validateCNPJ(value)
	}
}

export { 
	validateCNPJ, 
	validateCpf, 
	validateEmail, 
	validateCpfCnpj,
	validateDate,
	validatePhone,
	validatePhoneV2,
	validateCEP,
	validateName,
	validateFullName
}
  